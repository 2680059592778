import React from 'react'
import Layout from '../components/layout'
import { Link } from 'gatsby'

const NotFoundPage = () => (
  <Layout>
    <section className="hero is-primary is-primary">
      <div className="hero-body has-bg-img secondary">
        <div className="container has-text-centered">
          <span className="subtitle">oops!</span>
        </div>
      </div>
    </section>
    <section className="section">
      <div className="container home is-fluid">
        <h1 class="is-size-5">No page at this address, but if you were looking for music, podcast, or people, the links are below.</h1>
        <div className="columns">
          <div className="column">
            <p class="is-size-4"><Link to="/music">The music</Link></p>

            <p class="is-size-4"><Link to="/podcast">The podcast</Link></p>

            <p class="is-size-4"><Link to="/people">The people</Link></p>

            <div class="contact">
              Email us at <a href="mailto:planetandsky@gmail.com">planetandsky@gmail.com</a>. We'd love to hear your feedback.
            </div>

           </div>
         </div>
       </div>
    </section>
  </Layout>
)

export default NotFoundPage
